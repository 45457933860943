import { Auth } from '@aws-amplify/auth';
import type { CognitoUserSession } from 'amazon-cognito-identity-js';
import track from 'features/Analytics/track';
import { atom, useAtom } from 'jotai';
import * as React from 'react';
import type { SessionUser } from 'types';
import type { AuthStatus } from 'types/ui-bootstrap';

export const authStatusAtom = atom<AuthStatus>('Loading');
export const sessionUserAtom = atom<SessionUser | null>(null);

export interface IAuth {
  currentSession?: CognitoUserSession | undefined;
  currentUser?: SessionUser | null;
  authStatus: AuthStatus;
  signIn: (email: string, password: string) => void;
  signOut: VoidFunction;
  changePassword: (oldPassword: string, newPassword: string) => void;
  forgotPassword: (email: string) => void;
  forgotPasswordSubmit: (email: string, code: string, password: string) => void;
}

const AuthContext = React.createContext<IAuth | null>(null);

interface Props {
  children: React.ReactNode;
}

function AuthProvider({ children }: Props) {
  const [currentSession, setCurrentSession] = React.useState<CognitoUserSession>();
  const [currentUser, setCurrentUser] = useAtom(sessionUserAtom);
  const [authStatus, setAuthStatus] = useAtom(authStatusAtom);

  const isLoading = authStatus === 'Loading';
  React.useEffect(() => {
    async function getCurrentSession() {
      try {
        const session = await Auth.currentSession();
        setCurrentSession(session);
        const token = session.getIdToken();

        setCurrentUser({
          email: token.payload.email,
          userId: token.payload['custom:user_id'],
          clientId: token.payload['custom:client_id'],
          isAdmin: token.payload['custom:is_admin'],
        });

        setAuthStatus('SignedIn');
      } catch (err) {
        setCurrentSession(undefined);
        setAuthStatus('SignedOut');
      }
    }

    // Only execute this on first load
    if (isLoading) {
      getCurrentSession();
    }
  }, [isLoading, setAuthStatus, setCurrentUser]);

  async function signIn(email: string, password: string) {
    try {
      const user = await Auth.signIn(email, password);
      const { signInUserSession } = user;

      // Cognito forces a password change for all new users
      // This resets the password to the same initial password
      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        await Auth.completeNewPassword(user, password);
      }

      // Surface the Cognito information higher up as first class attributes
      // of the user object.

      setCurrentSession(signInUserSession);
      setCurrentUser({
        email: email,
        userId: user.signInUserSession.idToken.payload['custom:user_id'],
        clientId: user.signInUserSession.idToken.payload['custom:client_id'],
        isAdmin: user.signInUserSession.idToken.payload['custom:is_admin'],
      });
      track.signIn();

      setAuthStatus('SignedIn');
    } catch (err) {
      setAuthStatus('SignedOut');
      throw err;
    }
  }

  async function signOut() {
    try {
      await Auth.signOut();
      setAuthStatus('SignedOut');
      setCurrentUser(null);
      track.signOut();
    } catch (err) {
      throw err;
    }
  }

  async function changePassword(oldPassword: string, newPassword: string) {
    try {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(user, oldPassword, newPassword);
    } catch (err) {
      throw err;
    }
  }

  async function forgotPassword(email: string) {
    try {
      await Auth.forgotPassword(email);
    } catch (err) {
      throw err;
    }
  }

  async function forgotPasswordSubmit(email: string, code: string, password: string) {
    try {
      await Auth.forgotPasswordSubmit(email, code, password);
    } catch (err) {
      throw err;
    }
  }

  const state = {
    currentSession,
    currentUser,
    authStatus,
    signIn,
    signOut,
    changePassword,
    forgotPassword,
    forgotPasswordSubmit,
  };

  return <AuthContext.Provider value={state}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  const context = React.useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used in the AuthContext');
  }
  return context;
}

export default AuthProvider;
