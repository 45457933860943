import differenceInDays from 'date-fns/differenceInDays';
import format from 'date-fns/format';
import formatDistanceToNowStrict from 'date-fns/formatDistanceToNowStrict';
import isAfter from 'date-fns/isAfter';
import isYesterday from 'date-fns/isYesterday';
import subDays from 'date-fns/subDays';
import { isRouteErrorResponse } from 'react-router-dom';
import type { SortDirection } from 'types';

// export const hashData = (data: string) => {
//   crypto is no longer ploly-filled in webpack 5/react-scripts 5.
//   use SubtleCrypto instead? https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest
//   `return  await crypto.subtle.digest('SHA-1', data)`
//   return crypto.createHash('sha1').update(data).digest('base64');
// };

export const capitalize = ([firstLetter, ...restOfWord]: string) =>
  `${firstLetter.toUpperCase()}${restOfWord.join('')}`;

export const formatNumber = (value: number | string | null, digits = 0) => {
  if (value === null) {
    return '–';
  }
  const option = {
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  };
  if (typeof value === 'string') {
    value = parseFloat(value);
  }
  return new Intl.NumberFormat('en-us', option).format(value);
};

export const formatPercent = (value = 0, digits = 0) => {
  const option = {
    style: 'percent',
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  };
  return new Intl.NumberFormat('en-us', option).format(value);
};

export const formatCurrency = (value = 0, digits = 0) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  }).format(value);
};

interface DateProps {
  dateString: string | number;
  year: '2-digit' | 'numeric';
  month: '2-digit' | 'short' | 'long' | 'numeric';
  day?: '2-digit' | 'numeric' | undefined;
  hour?: 'numeric';
  minute?: 'numeric';
  second?: 'numeric';
  ignoreTimeZone?: boolean;
}

function formatDateString({ dateString, year, month, day, hour, minute, second, ignoreTimeZone }: DateProps) {
  return new Intl.DateTimeFormat('en-US', {
    year,
    month,
    day,
    hour,
    minute,
    second,
    timeZone: ignoreTimeZone ? 'UTC' : Intl.DateTimeFormat().resolvedOptions().timeZone,
  }).format(new Date(dateString));
}

type DateFormatFn = (
  dateString: DateProps['dateString'],
  year: DateProps['year'],
  month?: DateProps['month'],
  day?: DateProps['day'],
  hour?: DateProps['hour'],
  minute?: DateProps['minute'],
  second?: DateProps['second'],
  ignoreTimeZone?: DateProps['ignoreTimeZone']
) => string;

export const formatUTCTzDate: DateFormatFn = (dateString, year = '2-digit', month = 'short', day?) => {
  return formatDateString({ dateString, year, month, day, ignoreTimeZone: true });
};

export const formatDate: DateFormatFn = (
  dateString,
  year = '2-digit',
  month = 'short',
  day?,
  hour?,
  minute?,
  second?
) => {
  return formatDateString({ dateString, year, month, day, hour, minute, second });
};

export function formatDays(days: number) {
  if (Math.abs(days) < 89) {
    return `${days}d`;
  }
  const distance = formatDistanceToNowStrict(subDays(new Date(), days));
  return `${days < 0 ? '-' : ''}${distance.replace(/ months| month/, 'm').replace(/ years| year/, 'y')}`;
}

export function formatDateTime(dateString: string) {
  const date = new Date(dateString);
  const daysFromNow = differenceInDays(new Date(), date);
  if (daysFromNow < 1) {
    return `${formatDistanceToNowStrict(date)} ago`;
  } else if (daysFromNow < 10) {
    return isYesterday(date) ? 'yesterday' : `${daysFromNow} days ago`;
  }
  return formatDate(date.getTime(), 'numeric', '2-digit', '2-digit', 'numeric', 'numeric');
}

export function convertToInternationalCurrencySystem(labelValue: string | number = '', prefix = '', digits = 2) {
  const num = Math.abs(Number(labelValue));

  switch (true) {
    // Nine Zeroes for Billions
    case num >= 1.0e9:
      return `${prefix}${(num / 1.0e9).toFixed(digits)}B`;
    // Six Zeroes for Millions
    case num >= 1.0e6:
      return `${prefix}${(num / 1.0e6).toFixed(digits)}M`;
    // Three Zeroes for Thousands
    case num >= 1.0e3:
      return `${prefix}${(num / 1.0e3).toFixed(digits)}K`;
    default:
      return `${prefix}${num.toFixed(digits)}`;
  }
}

export const generateRandomNumber = (minInput = 0, maxInput = 100) => {
  const min = Math.ceil(minInput);
  const max = Math.floor(maxInput);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export function stringToColor(str: string) {
  let hash = 0;
  let i: number;

  /* eslint-disable no-bitwise */
  for (i = 0; i < str.length; i += 1) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

export function generateAvatarProps(name: string) {
  return {
    // sx: {
    //   bgcolor: stringToColor(name),
    // },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  };
}

function normalizeCase<Type>(prop: Type) {
  if (typeof prop === 'string') {
    return prop.toUpperCase() as unknown as Type;
  }
  return prop;
}

export function compare<Resource>(a: Resource, b: Resource, orderBy: keyof Resource, desc: boolean): -1 | 1 | 0 {
  // String comparisons are case-insensitive
  const left = normalizeCase(a[orderBy]);
  const right = normalizeCase(b[orderBy]);

  if (left === right) return 0;

  if (left === null || typeof left === 'undefined') {
    return 1;
  }
  if (right === null || typeof right === 'undefined') {
    return -1;
  }
  if (desc) {
    return right < left ? 1 : -1;
  } else {
    return right < left ? -1 : 1;
  }
}

export function sortTableValues<Resource>(
  array: readonly Resource[],
  order: 'asc' | 'desc',
  orderBy: keyof Resource | null
) {
  if (orderBy === null) {
    return array;
  }
  return stableSort(array, getComparator(order, orderBy));
}

export function getComparator<Resource>(order: 'asc' | 'desc', orderBy: keyof Resource) {
  return (a: Resource, b: Resource) => compare(a, b, orderBy, order === 'desc');
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
export function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export function adjustForUTCOffset(date: Date) {
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  );
}

type LTMRange = {
  earliest: string;
  latest: string;
};
export function getLTMRange(range?: LTMRange) {
  if (!range) {
    return 'Data for the last 12 months';
  }
  const { earliest, latest } = range;

  const fromDate = format(adjustForUTCOffset(new Date(earliest)), 'MMMM yyyy');
  const toDate = format(adjustForUTCOffset(new Date(latest)), 'MMMM yyyy');

  return `${fromDate} to ${toDate}`;
}

export function isValidDate(d?: Date | null) {
  return d !== null && d instanceof Date && !isNaN(d.getTime());
}

function ensureNumber(n: string | number): number {
  return typeof n === 'string' ? parseFloat(n) : n;
}

export function getNumberWithOrdinal(n: string | number) {
  const s = ['th', 'st', 'nd', 'rd'];
  const v = ensureNumber(n) % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
}

export function getRandomInt(min: number, max: number) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min);
}

export function getInitials(str: string) {
  const split = str.split(' ');
  if (split.length > 2) {
    return `${split[0][0]}${split[split.length - 1][0]}`;
  }
  return split.map((word) => word[0]).join('');
}

export function downloadBlob(blob: Blob, filename: string) {
  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);

  link.setAttribute('href', url);
  link.setAttribute('download', filename);
  link.style.visibility = 'hidden';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);

  setTimeout(() => {
    URL.revokeObjectURL(link.href);
    if (link.parentNode) link.parentNode.removeChild(link);
  }, 0);
}

function getIsSafari() {
  return (
    /iP(ad|od|hone)/i.test(window.navigator.userAgent) && !!window.navigator.userAgent.match(/Version\/[\d\.]+.*Safari/)
  );
}

export function viewBlobInANewTab(blob: Blob, fileName: string) {
  const isSafari = getIsSafari();
  if (isSafari) {
    downloadBlob(blob, fileName);
  } else {
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);

    link.setAttribute('href', url);
    link.setAttribute('target', '_blank');
    link.setAttribute('rel', 'noreferrer');
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    setTimeout(() => {
      URL.revokeObjectURL(link.href);
      if (link.parentNode) link.parentNode.removeChild(link);
    }, 0);
  }
}

export function round(num: number, decimals: number) {
  return +`${Math.round(Number(`${num.toString()}e+${decimals}`))}e-${decimals}`;
}

export function roundToOne(num: number) {
  return round(num, 1);
}

export function roundToTwo(num: number) {
  return round(num, 2);
}

export function isNumber(value: unknown): boolean {
  return typeof value === 'number';
}

export function getErrorMessage(error: unknown) {
  if (error instanceof Error) return error.message;
  return String(error);
}

export function getErrorResponseMessage(error: unknown) {
  if (isRouteErrorResponse(error)) {
    if ('error' in error.data && 'message' in error.data.error) {
      return error.data.error.message;
    } else if (error.data) {
      return JSON.stringify(error.data);
    }
  }
  return getErrorMessage(error);
}

export function isAfterToday(dateString: string) {
  if (!dateString) return false;
  const today = new Date();
  const expiration = new Date(dateString);
  return isAfter(expiration, today);
}

export function formatBytes(bytes: number, decimals = 1) {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

export function truncateName(name: string, maxChars: number): string {
  if (name.length > maxChars) {
    return `${name.substring(0, maxChars)}[...]`;
  }
  return name;
}

const KEEP_COMMA_REPLACEMENT = '___COMMA___';
const SPLIT_COMMA_REPLACEMENT = '___SPLIT___';
// This needs a better name
export function parseSelections(value: string | null, keep = KEEP_COMMA_REPLACEMENT, split = SPLIT_COMMA_REPLACEMENT) {
  return typeof value === 'string'
    ? value.replaceAll(', ', keep).replaceAll(',', split).replaceAll(keep, ', ').split(split).map(decodeURIComponent)
    : undefined;
}

export function isValidSortDir(dir: string): dir is SortDirection {
  return ['asc', 'desc'].includes(dir);
}

export interface FormatTableNumberOptions {
  type?: 'percent' | 'currency';
  round?: boolean;
}

export function formatTableNumber(n: number | null, options?: FormatTableNumberOptions) {
  const { type, round } = options || {};

  if (typeof n === 'number') {
    if (type === 'percent') {
      return `${(n * 100).toFixed(1)}%`;
    } else if (type === 'currency') {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(n);
    }
    return round ? roundToOne(n).toFixed(1) : formatNumber(n);
  }
  return '–';
}

export default {
  capitalize,
  formatNumber,
  formatPercent,
  formatCurrency,
  formatDate,
  convertToInternationalCurrencySystem,
  generateRandomNumber,
  stringToColor,
  generateAvatarProps,
  compare,
  getComparator,
  stableSort,
  getLTMRange,
  getNumberWithOrdinal,
  getRandomInt,
  getInitials,
  downloadBlob,
  roundToTwo,
};
