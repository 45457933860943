import type { ClientNameMap } from 'types/client-systems';
import type { ExceptionStatus } from 'types/exceptions';

import { SelectOption } from './../types/index';

export const API_VERSION = 'v1';

const { REACT_APP_DEPLOY_ENV } = process.env;

export const IS_NOT_PRODUCTION =
  REACT_APP_DEPLOY_ENV === 'qa' ||
  REACT_APP_DEPLOY_ENV === 'development' ||
  REACT_APP_DEPLOY_ENV === 'staging' ||
  REACT_APP_DEPLOY_ENV === 'integration';

export const PO_LINE_STATUSES: ExceptionStatus[] = ['New', 'In Progress', 'Resolved'];
export const PO_LINE_STATUS_SELECT_OPTIONS: SelectOption[] = PO_LINE_STATUSES.map((status) => ({
  name: status,
  value: status,
}));

export const ITEM_STATUSES: ExceptionStatus[] = ['New', 'In Progress', 'Resolved'];
export const ITEM_STATUS_SELECT_OPTIONS: SelectOption[] = ITEM_STATUSES.map((status) => ({
  name: status,
  value: status,
}));

/** === TIME === */
export const MS_PER_SECOND = 1000;
export const SECONDS_PER_MINUTE = 60;
export const MINUTES_PER_HOUR = 60;
export const ONE_MINUTE = SECONDS_PER_MINUTE * MS_PER_SECOND;
export const ONE_HOUR = ONE_MINUTE * MINUTES_PER_HOUR;

export const SPOOFED_LS_KEY = 'spoofed-client-id';

export const CLIENT_NAMES: ClientNameMap = {
  clarium: 'Clarium Health',
  bch: `Boston Children's Hospital`,
  ghs: 'Geisinger',
  ynhh: 'Yale New Haven Hospital',
  csh: 'CommonSpirit Health',
};
