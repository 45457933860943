import { TASK_MANAGER_ACTION_ROUTE } from 'pages/Disruption/SupplyChainSnapshot/TaskManager/utils';
import { PREF_CARDS_BASE_ROUTE } from 'pages/preference-cards/basePath';
import { Route, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" lazy={() => import('Root')}>
      {/* Redirects */}
      <Route path="/" lazy={() => import('components/RouteContainers/LandingRedirect')} />
      {/* Auth / Profile */}
      <Route path="/reset_password" lazy={() => import('pages/ForgotPassword')} />
      <Route path="/login" lazy={() => import('pages/Login')} />
      <Route path="/logout" lazy={() => import('pages/Logout')} />
      <Route path="/profile" lazy={() => import('pages/Profile')} />
      <Route path="/spoof" lazy={() => import('pages/Spoofing')} />
      <Route path="dashboard" lazy={() => import('pages/Dashboard')} />
      <Route path="exceptions" lazy={() => import('pages/Exceptions/PoLine')}>
        <Route index lazy={() => import('pages/Exceptions/PoLine/Queue/Page')} />
        <Route path="po-line/*" lazy={() => import('pages/Exceptions/PoLine/po-line-redirect')} />
        <Route path=":poId" lazy={() => import('pages/Exceptions/PoLine/Details')} />
      </Route>
      <Route path="disruptions" id="drm-root" lazy={() => import('pages/Disruption/routes/disruptions')}>
        <Route index lazy={() => import('pages/Disruption/routes/disruptions.index')} />
        <Route
          path=":disruptionId"
          id="drm-item"
          lazy={() => import('pages/Disruption/routes/disruptions.$disruptionId')}
        >
          <Route
            index
            id="drm-item-params"
            lazy={() => import('pages/Disruption/routes/disruptions.$disruptionId.index')}
          />
          <Route path="inventory" lazy={() => import('pages/Disruption/routes/disruptions.$disruptionId.inventory')} />
          <Route
            path="utilization"
            lazy={() => import('pages/Disruption/routes/disruptions.$disruptionId.utilization')}
          />
          <Route path="vendors" lazy={() => import('pages/Disruption/routes/disruptions.$disruptionId.vendors')} />
          <Route
            path="substitutes"
            lazy={() => import('pages/Disruption/routes/disruptions.$disruptionId.substitutes')}
          >
            <Route
              path="request"
              lazy={() => import('pages/Disruption/routes/disruptions.$disruptionId.substitutes.request')}
            />
          </Route>

          <Route
            path={TASK_MANAGER_ACTION_ROUTE}
            lazy={() => import('pages/Disruption/SupplyChainSnapshot/TaskManager/taskManagerAction')}
          />
        </Route>
      </Route>
      <Route path="procedural-analytics" lazy={() => import('pages/ProceduralAnalytics/routes/procedural-analytics')}>
        <Route index lazy={() => import('pages/ProceduralAnalytics/routes/procedural-analytics.index')} />
        <Route
          path="surgeon/:surgeonId"
          lazy={() => import('pages/ProceduralAnalytics/routes/procedural-analytics.surgeon.$surgeonId')}
        >
          <Route
            path=":caseId"
            lazy={() => import('pages/ProceduralAnalytics/routes/procedural-analytics.surgeon.$surgeonId.$caseId')}
          />
        </Route>
      </Route>
      <Route id="cli-root" path="clinical-intelligence" lazy={() => import('./pages/Intelligence/routes/intelligence')}>
        <Route id="cli-index" index lazy={() => import('pages/Intelligence/routes/intelligence.index')} />
        <Route
          id="cli-details"
          path=":entityType/:entityId"
          lazy={() => import('pages/Intelligence/routes/intelligence.detail')}
        />
      </Route>
      <Route path="substitutes" lazy={() => import('pages/substitutes/routes/substitutes')}>
        <Route index lazy={() => import('pages/substitutes/routes/substitutes.index')} />
        <Route path=":substituteId" lazy={() => import('pages/substitutes/routes/substitutes.$substituteId')}>
          <Route path="request" lazy={() => import('pages/substitutes/routes/substitutes.$substituteId.request')} />
          <Route path="edit" lazy={() => import('pages/substitutes/routes/substitutes.$substituteId.edit')} />
        </Route>
      </Route>
      <Route path="/create-substitute-request" lazy={() => import('pages/root/create-substitute-request')} />
      <Route path="/upload-substitute-image" lazy={() => import('pages/root/upload-substitute-image')} />
      <Route
        path="external/substitutes/approvals/:requestId"
        lazy={() => import('pages/external/substitutes/approvals.route')}
      />
      <Route
        id="dpc-root"
        path={PREF_CARDS_BASE_ROUTE}
        lazy={() => import('pages/preference-cards/routes/preference-cards')}
      >
        <Route index id="dpc-index" lazy={() => import('pages/preference-cards/routes/preference-cards.index')} />
        <Route
          path="requests"
          id="dpc-index-request-list"
          lazy={() => import('pages/preference-cards/routes/preference-cards.requests')}
        />
        <Route
          path=":cardId"
          id="dpc-card"
          lazy={() => import('pages/preference-cards/routes/preference-cards.$cardId')}
        >
          <Route
            index
            id="dpc-card-index"
            lazy={() => import('pages/preference-cards/routes/preference-cards.$cardId.index')}
          />
          <Route
            path="requests"
            id="dpc-card-requests"
            lazy={() => import('pages/preference-cards/routes/preference-cards.$cardId.requests')}
          />
        </Route>
        <Route
          path=":cardId/requests/:requestId"
          id="dpc-card-request-actions"
          lazy={() => import('pages/preference-cards/routes/preference-cards.$cardId.requests.$requestId')}
        />
      </Route>
      <Route path="*" lazy={() => import('pages/NotFound')} />
    </Route>
  )
);

export default router;
