import mixpanel from 'mixpanel-browser';

const { REACT_APP_DEPLOY_ENV } = process.env;

const isProduction = REACT_APP_DEPLOY_ENV === 'production';

const MX_DEV = '5ce164a570f20c83bf648b03e2a7c062';
const MX_PROD = '8df242d1031d1bcc3c4d9143ed68a4aa';

const token = isProduction ? MX_PROD : MX_DEV;

mixpanel.init(token, { debug: !isProduction, ignore_dnt: true });

type ModuleCode = 'DRM' | 'PCA' | 'EXM' | 'SUB' | 'CLI' | 'PCM';
type PageType = 'Queue' | 'Detail';
export type DrmMonitoringOnAction =
  | 'Monitor Toggle'
  | 'Assign Team'
  | 'Assign User'
  | 'Change ETA'
  | 'Change Disruption Type';
export type ExmAction = 'Assign Team' | 'Assign User' | 'Assign Root Cause' | 'Export';
export type ExmBulkAction = 'Reassign' | 'Comment' | 'Export' | 'Transition';
export type ExmTransitionAction = ExmAction | 'Bulk' | 'Start Task Button' | 'Comment';
export type PCAPage = 'explore' | 'surgeon' | 'surgeon case';
type SpoofUserArgs = {
  userId: string;
  userName: string;
  userClientId: string;
  spoofedUserId: string;
  spoofedUserClientId: string;
};

const track = {
  pageView: (pathname: string, basePath: string) => {
    mixpanel.track('Page View', { pathname, basePath });
  },
  openFilters: (moduleCode: ModuleCode) => {
    mixpanel.track('Open Filters', { moduleCode });
  },
  changeFilters: (moduleCode: ModuleCode, filters: string[]) => {
    mixpanel.track('Change Filters', { moduleCode, filterLocation: 'Drawer', filters });
  },
  changeFiltersInPill: (moduleCode: ModuleCode, filterName: string) => {
    mixpanel.track('Change Filter Pill', { moduleCode, filterName, filterLocation: 'Pill' });
  },
  removeFiltersInPill: (moduleCode: ModuleCode, filterName: string) => {
    mixpanel.track('Remove Filter Pill', { moduleCode, filterName, filterLocation: 'Pill' });
  },
  customizeColumns: (moduleCode: ModuleCode, columns: string[]) => {
    mixpanel.track('Customize Columns', { moduleCode, columns });
  },
  paginateTable: (moduleCode: ModuleCode, pageNumber: number, dir: 'Forward' | 'Back') => {
    mixpanel.track('Paginate Table', { moduleCode, pageNumber, dir });
  },
  filterTable: (moduleCode: ModuleCode) => {
    mixpanel.track('Filter Table', { moduleCode });
  },
  addComment: (moduleCode: ModuleCode, attachmentCount: number) => {
    mixpanel.track('Add Comment', { moduleCode, attachmentCount });
  },
  startSpoof: (spoofed: SpoofUserArgs) => {
    mixpanel.track('Start Spoof', spoofed);
  },
  cli: {
    changeFilters: (filters: string[]) => {
      mixpanel.track('Change Filters', { moduleCode: 'CLI', filters, filterLocation: 'Drawer' });
    },
  },
  pcm: {
    changeFilters: (filters: string[]) => {
      mixpanel.track('Change Filters', { moduleCode: 'PCM', filters, filterLocation: 'Drawer' });
    },
    requestCreated: () => {
      mixpanel.track('Request Created', { moduleCode: 'PCM' });
    },
    requestCompleted: () => {
      mixpanel.track('Request Completed', { moduleCode: 'PCM' });
    },
    requestCancelled: () => {
      mixpanel.track('Request Cancelled', { moduleCode: 'PCM' });
    },
    acceptAll: () => {
      mixpanel.track('Accept All Recommendations', { moduleCode: 'PCM' });
    },
    snoozeAll: () => {
      mixpanel.track('Snooze All Recommendations', { moduleCode: 'PCM' });
    },
    viewCases: () => {
      mixpanel.track('View Cases', { moduleCode: 'PCM' });
    },
    addComment: (entity: 'card' | 'request', attachmentCount: number) => {
      mixpanel.track('Add Comment', { moduleCode: 'PCM', entity, attachmentCount });
    },
    changeFilterInToolbar: (filterName: string) => {
      mixpanel.track('Change Filters', { moduleCode: 'PCM', filterLocation: 'Toolbar', filters: [filterName] });
    },
  },
  pca: {
    changeFilters: (filters: string[]) => {
      mixpanel.track('Change Filters', { moduleCode: 'PCA', filters, filterLocation: 'Drawer' });
    },
    changePeriod: (period: string, page: PCAPage = 'explore') => {
      mixpanel.track('Change Period', { moduleCode: 'PCA', period, page });
    },
    changeView: (primary: string, secondary: string) => {
      mixpanel.track('Change View', { moduleCode: 'PCA', primary, secondary });
    },
    changeSubModule: (subModule: string) => {
      mixpanel.track('Change Sub Module', { moduleCode: 'PCA', subModule });
    },
    changeSort: (sort: string, dir: string, page: PCAPage = 'explore') => {
      mixpanel.track('Change Sort', { moduleCode: 'PCA', sort, dir, page });
    },
    changeFilterInPill: (filterName: string) => {
      mixpanel.track('Change Filter Pill', { moduleCode: 'PCA', filterName, filterLocation: 'Pill' });
    },
    removeFilterPill: (filterName: string) => {
      mixpanel.track('Remove Filter Pill', { moduleCode: 'PCA', filterName });
    },
    changeChart: (chart: string, page: PCAPage) => {
      mixpanel.track('Change Chart', { moduleCode: 'PCA', chart, page });
    },
    changeSurgeonFilter: (filter: 'procedure_name', value: string) => {
      mixpanel.track('Change Surgeon Filter', { moduleCode: 'PCA', filter, value });
    },
  },
  drm: {
    toggleMonitoring: (monitoring: boolean, page: PageType, action: DrmMonitoringOnAction) => {
      mixpanel.track('Toggle Monitoring', {
        moduleCode: 'DRM',
        monitoring: monitoring ? 'On' : 'Off',
        page,
        action,
      });
    },
    updateDisruption: (page: PageType, action: DrmMonitoringOnAction) => {
      mixpanel.track('Update Disruption', { moduleCode: 'DRM', page, action });
    },
    changeInventoryLocation: (inventoryLocation: string, page: PageType) => {
      mixpanel.track('Change Inventory Location', { moduleCode: 'DRM', inventoryLocation, page });
    },
    changePeriod: (period: string, page: PageType) => {
      mixpanel.track('Change Period', { moduleCode: 'DRM', period, page });
    },
    changeUOM: (uom: string, page: PageType) => {
      mixpanel.track('Change UOM', { moduleCode: 'DRM', uom, page });
    },
    focusSearchByErpId: () => {
      mixpanel.track('Focus Search By ERP ID', { moduleCode: 'DRM' });
    },
    clickSearchByErpIdResult: () => {
      mixpanel.track('Click Search By ERP ID Result', { moduleCode: 'DRM' });
    },
    resultCount: (resultCount: number) => {
      mixpanel.track('Result Count', { moduleCode: 'DRM', resultCount });
    },
    dashboardChangeFilters: (filter: 'time-period', value: string) => {
      mixpanel.track('Dashboard Change Filters', { moduleCode: 'DRM', filter, value });
    },
    createRequest: (attrs: { primaryImage: boolean; subImage: boolean; attachmentCount: number }) => {
      mixpanel.track('Create Substitute Request', { moduleCode: 'DRM', ...attrs });
    },
  },
  exm: {
    startTask: (page: PageType, action: ExmTransitionAction) => {
      mixpanel.track('Start Task', { moduleCode: 'EXM', page, action });
    },
    updateException: (page: PageType, action: ExmAction) => {
      mixpanel.track('Update Disruption', { moduleCode: 'EXM', page, action });
    },
    bulkUpdateExceptions: (action: ExmBulkAction, bulkUpdateCount: number) => {
      mixpanel.track('Bulk Update Exceptions', { moduleCode: 'EXM', action, bulkUpdateCount });
    },
    paginateDetailPage: (dir: 'Forward' | 'Back') => {
      mixpanel.track('Paginate Detail Page', { moduleCode: 'EXM', dir });
    },
    dashboardChangeFilters: (filter: 'team' | 'users' | 'vendors') => {
      mixpanel.track('Dashboard Change Filters', { moduleCode: 'EXM', filter });
    },
  },
  sub: {
    changeStatus: (status: string) => {
      mixpanel.track('Change Status', { moduleCode: 'SUB', status });
    },
    updateSubstitute: () => {
      mixpanel.track('Replace Substitute', { moduleCode: 'SUB' });
    },
    createRequest: (attrs: { primaryImage: boolean; subImage: boolean; attachmentCount: number }) => {
      mixpanel.track('Create Request', { moduleCode: 'SUB', ...attrs });
    },
    addSubstitute: (attrs: { primaryImage: boolean; subImage: boolean; attachmentCount: number }) => {
      mixpanel.track('Add Substitute to Request', { moduleCode: 'SUB', ...attrs });
    },
    changeFilters: (filters: string[]) => {
      mixpanel.track('Change Filters', { moduleCode: 'SUB', filters, filterLocation: 'Drawer' });
    },
    openRequestSubstituteModal: () => {
      mixpanel.track('Open Request Substitute Modal', { moduleCode: 'SUB' });
    },
    selectSecondaryItem: (itemId: string) => {
      mixpanel.track('Select Secondary Item', { moduleCode: 'SUB', itemId });
    },
    filterTable: (term: string) => {
      mixpanel.track('Filter Table', { moduleCode: 'SUB', term });
    },
    addReviewer: () => {
      mixpanel.track('Add Reviewer', { moduleCode: 'SUB' });
    },
    removeReviewer: () => {
      mixpanel.track('Remove Reviewer', { moduleCode: 'SUB' });
    },
    externalChangeStatus: (status: string) => {
      mixpanel.track('External Change Status', { moduleCode: 'SUB', status, external: true });
    },
    externalAddReviewer: () => {
      mixpanel.track('Add Reviewer', { moduleCode: 'SUB', external: true });
    },
    uploadSubstituteImage: (substituteType: 'Primary' | 'Substitution') => {
      mixpanel.track('Upload Substitute Image', { moduleCode: 'SUB', substituteType });
    },
  },
  signIn: () => {
    mixpanel.track('Sign In');
  },
  signOut: () => {
    mixpanel.track('Sign Out');
  },
};

export default track;
