import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { LEVEL_WARN, ErrorBoundary as RollbarErrorBoundary, Provider as RollbarProvider } from '@rollbar/react';
import { QueryClientProvider } from '@tanstack/react-query';
import jotaiStore from 'atoms/store';
import Loading from 'components/Loading';
import AuthProvider from 'contexts/AuthContext';
import { useAuth } from 'contexts/AuthContext';
import FontFaceObserver from 'fontfaceobserver';
import { Provider } from 'jotai';
import { ReactElement, useEffect, useState } from 'react';
import * as React from 'react';
import { RouterProvider } from 'react-router-dom';
import Rollbar from 'rollbar';
import { queryClient } from 'utils/api';
import { IS_NOT_PRODUCTION } from 'utils/constants';

import { ErrorFallback } from './ErrorFallback';
import router from './Routes';

const { REACT_APP_DEPLOY_ENV } = process.env;

const isDev = REACT_APP_DEPLOY_ENV === 'development';

// Preload Webfonts
const fontsToLoad = [
  { family: 'IBM Plex Sans', weight: 400 },
  { family: 'IBM Plex Sans', weight: 500 },
  { family: 'IBM Plex Sans', weight: 600 },
];
const fontObservers = fontsToLoad.map(({ family, weight }) => {
  return new FontFaceObserver(family, { weight }).load();
});

const rollbarConfig: Rollbar.Configuration = {
  accessToken: '11090e1890554e2a840f3443d097f71b',
  environment: REACT_APP_DEPLOY_ENV,
  captureUncaught: true,
  captureUnhandledRejections: true,
  autoInstrument: !isDev,
  payload: {
    client: {
      javascript: {
        code_version: '1.0.0',
        source_map_enabled: true,
      },
    },
  },
};
export const rollbar = new Rollbar(rollbarConfig);

export function App() {
  const [isFontReady, setIsFrontReady] = useState(false);

  useEffect(() => {
    Promise.all(fontObservers)
      .catch((err) => {
        console.warn('Some critical font are not available:', err);
      })
      .finally(() => {
        setIsFrontReady(true);
      });
  }, []);

  // TODO: Add loading indicator for font isFontReady check
  return (
    <React.StrictMode>
      <RollbarProvider config={rollbarConfig}>
        <RollbarErrorBoundary level={LEVEL_WARN} fallbackUI={ErrorFallback as unknown as React.ReactNode}>
          <Provider store={jotaiStore}>
            <QueryClientProvider client={queryClient}>
              <AuthProvider>
                {isFontReady && (
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <React.Suspense fallback={<Loading />}>
                      <RouterProvider router={router} />
                    </React.Suspense>
                  </LocalizationProvider>
                )}
              </AuthProvider>
            </QueryClientProvider>
          </Provider>
        </RollbarErrorBoundary>
      </RollbarProvider>
    </React.StrictMode>
  );
}

export default App;
