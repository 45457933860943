import { createTheme } from '@mui/material/styles';
import { extendedPalette } from 'styles/extendedPalette';
import { paletteV2 } from 'styles/palette_v2';

// A custom theme for this app

export const themeConfig = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1440,
    },
  },
  typography: {
    fontFamily: ['IBM Plex Sans'].join(','),
  },
  palette: {
    primary: {
      light: extendedPalette.peri['3'],
      main: extendedPalette.peri['2'],
      dark: extendedPalette.peri['1'],
      contrastText: '#fff',
    },
    secondary: {
      light: extendedPalette.midnight['3'],
      main: extendedPalette.midnight['2'],
      dark: extendedPalette.midnight['1'],
      contrastText: '#fff',
    },
    tertiary: {
      light: extendedPalette.midnight['5'],
      main: extendedPalette.midnight['4'],
      dark: extendedPalette.midnight['3'],
    },
    v2_primary: {
      light: paletteV2.primary['2'],
      main: paletteV2.primary['5'],
      dark: paletteV2.primary['7'],
      contrastText: '#fff',
    },
    v2_secondary: {
      light: paletteV2.primary['0.5'],
      main: paletteV2.primary['1'],
      dark: paletteV2.primary['2'],
      contrastText: paletteV2.primary['5'],
    },
    v2_tertiary: {
      light: paletteV2.neutral['5'],
      main: paletteV2.neutral['8'],
      dark: paletteV2.neutral['10'],
      contrastText: '#fff',
    },
    error: {
      light: extendedPalette.flamingo['5'],
      main: extendedPalette.flamingo['2'],
      dark: extendedPalette.flamingo['1'],
    },
    warning: {
      light: extendedPalette.sunflower['5'],
      main: extendedPalette.sunflower['2'],
      dark: extendedPalette.sunflower['1'],
    },
    info: {
      light: extendedPalette.plum['5'],
      main: extendedPalette.plum['2'],
      dark: extendedPalette.plum['1'],
    },
    success: {
      light: extendedPalette.forest['5'],
      main: extendedPalette.forest['3'],
      dark: extendedPalette.forest['2'],
    },
    background: {
      default: '#fff',
    },
    ...extendedPalette,
  },
  // overrides: {
  //   MuiPaper: {
  //     root: {
  //       padding: '20px 10px',
  //       margin: '10px',
  //       backgroundColor: '#fff', // 5d737e
  //     },
  //   },
  //   MuiButton: {
  //     root: {
  //       margin: '5px',
  //     },
  //   },
  // },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          fontSize: '14px',
          fontWeight: 500,
          textTransform: 'none' as const,
          whiteSpace: 'nowrap' as const,
          borderRadius: '4px',
        },
        sizeSmall: {
          height: '28px',
        },
      },
    },
  },
};

const theme = createTheme(themeConfig);
export default theme;
