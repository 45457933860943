// Defaults to weight 400.
import '@fontsource/ibm-plex-sans';
import '@fontsource/ibm-plex-sans/500.css';
import '@fontsource/ibm-plex-sans/600.css';
import CssBaseline from '@mui/material/CssBaseline';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';
import { ThemeProvider } from '@mui/material/styles';
import 'aws';
import { createRoot } from 'react-dom/client';
import TagManager from 'react-gtm-module';
import { Helmet } from 'react-helmet';
import 'whatwg-fetch';

import { App } from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';
import theme from './theme';

const { REACT_APP_DEPLOY_ENV } = process.env;

const tagManagerArgs = {
  gtmId: 'GTM-PVGVSVV',
};

if (REACT_APP_DEPLOY_ENV !== 'development') {
  // Google Tag Manager
  TagManager.initialize(tagManagerArgs);
}

const container = document.getElementById('root');
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Helmet titleTemplate="%s | Clarium" />
      <App />
    </ThemeProvider>
  </StyledEngineProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
