import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Stack from '@mui/material/Stack';
import { ReactElement, useEffect, useState } from 'react';
import { getRandomInt } from 'utils/helpers';

export interface Props {
  speed?: number;
}

/* Component */

export const Loading = ({ speed = 100 }: Props): ReactElement => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          return 100;
        }
        const diff = getRandomInt(10, 20);
        return Math.min(oldProgress + diff, 100);
      });
    }, speed);

    return () => {
      clearInterval(timer);
    };
  }, [speed]);

  return (
    <Stack alignItems="center" justifyContent="center">
      <Box sx={(theme) => ({ width: '100%', color: theme.palette.primary.main })}>
        <LinearProgress variant="determinate" value={progress} color="inherit" />
      </Box>
    </Stack>
  );
};

export default Loading;
