import type { Theme } from '@mui/material/styles';
import { PaletteV2, V2ColorVariant, V2Shade } from 'types/colors';

export const paletteV2: PaletteV2 = {
  primary: {
    0.5: '#EBEBFF',
    1: '#D8D5FF',
    2: '#B4B1FF',
    3: '#908DFF',
    4: '#6C69FF',
    5: '#4845FF',
    6: '#3C3CE0',
    7: '#3232BE',
    8: '#28289C',
    9: '#1E1E7A',
    10: '#141458',
    11: '#0A0A36',
    12: '#000014',
  },
  alert: {
    0.5: '#FFECEB',
    0: '#FFC8C5',
    1: '#FF6A62',
    5: '#FF3B30',
    10: '#C22D24',
  },
  caution: {
    0.5: '#FFF7EB',
    0: '#FFE1B8',
    1: '#FFAE3D',
    5: '#FF9500',
    10: '#C27100',
  },
  attention: {
    0.5: '#FFFBEB',
    0: '#FFF1B8',
    1: '#FFD83D',
    5: '#FFCC00',
    10: '#B29B00',
  },
  success: {
    0.5: '#EFFBF2',
    0: '#C6EFD1',
    1: '#65D481',
    5: '#34C759',
    10: '#1F7735',
  },
  info: {
    0.5: '#E5F2ff',
    0: '#B8DAFF',
    1: '#3D9BFF',
    5: '#005CBF',
    10: '#004A99',
  },
  neutral: {
    0.5: '#FAFAFA',
    1: '#F2F2F7',
    2: '#E5E5EA',
    3: '#D1D1D6',
    4: '#C7C7CC',
    5: '#AEAEB2',
    6: '#8E8E93',
    7: '#8E8E93',
    8: '#636366',
    9: '#48484A',
    10: '#3A3A3C',
    11: '#2C2C2E',
    12: '#1C1C1E',
  },
  extremes: {
    black: '#000205',
  },
};

export function getV2Color<V extends V2ColorVariant>(theme: Theme, variant: V, shade: V2Shade<V>) {
  return theme.palette.__v2__[variant][shade];
}
