import { ExtendedPalette } from 'types/colors';

import { paletteV2 } from './palette_v2';

export const extendedPalette: ExtendedPalette = {
  peri: {
    1: '#2026C5',
    2: '#5B57FF',
    3: '#929AEC',
    4: '#CACEF7',
    5: '#E5E7FB',
  },
  midnight: {
    1: '#000000',
    2: '#111751',
    3: '#7E90A5',
    4: '#CDD3DA',
    5: '#E7EAF3',
  },
  forest: {
    1: '#2C865B',
    2: '#3CB47A',
    3: '#66CC9B',
    4: '#C2EBD7',
    5: '#E8F7F0',
  },
  flamingo: {
    1: '#DF0C4E',
    2: '#F53873',
    3: '#FF5389',
    4: '#FFE0EA',
    5: '#FFEBF1',
  },
  sunflower: {
    1: '#E59A00',
    2: '#FAA700',
    3: '#FFC142',
    4: '#FFE9BD',
    5: '#FFF5E0',
  },
  aqua: {
    1: '#17B3A3',
    2: '#0EDDC8',
    3: '#71EFE3',
    4: '#C4F3F3',
    5: '#E1FEFB',
  },
  sky: {
    1: '#005BC5',
    2: '#0076FF',
    3: '#62A6F4',
    4: '#CCE3FF',
    5: '#E6F1FF',
  },
  plum: {
    1: '#6F05F0',
    2: '#943CFF',
    3: '#AD79ED',
    4: '#EAD8FF',
    5: '#F5ECFF',
  },
  __v2__: paletteV2,
};
