import { Auth } from '@aws-amplify/auth';
import { QueryClient } from '@tanstack/react-query';
import { rollbar } from 'App';
import { SpoofingClientId } from 'types/spoofing';
import type { UiBootstrapResponse } from 'types/ui-bootstrap';
import { ONE_HOUR, SPOOFED_LS_KEY } from 'utils/constants';

// import { debug } from 'utils/debug';

const { REACT_APP_JIT_API, REACT_APP_DEPLOY_ENV, REACT_APP_API_URL, REACT_APP_MOCK } = process.env;

function checkStatus(response: Response) {
  const isValidStatusCode = response.status >= 200 && response.status < 300;
  if (!isValidStatusCode) {
    throw response;
  }
}

function extractData<JSONResponse>(json: { data: JSONResponse }) {
  const { data } = json;
  if (!data) {
    throw new Error('Invalid JSON Response');
  }
  return data;
}

export function getJitUrl(endpoint: string) {
  if (REACT_APP_DEPLOY_ENV !== 'development') {
    return `${REACT_APP_JIT_API}${endpoint}`;
  }
  return `/api-jit${endpoint}`;
}

function getUrl(endpoint: string) {
  if (REACT_APP_DEPLOY_ENV !== 'development') {
    return `${REACT_APP_API_URL}${endpoint}`;
  } else if (REACT_APP_MOCK === 'msw') {
    return endpoint;
  }
  return `/api${endpoint}`;
}

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      onError(err) {
        const pathname = window.location.pathname;
        if (err === 'No current user' && !pathname.startsWith('/external')) {
          window.location.href = `${window.location.origin}/login`;
        } else {
          rollbar.error('API error caught in QueryClient', err as Error);
        }
      },
    },
  },
});
export enum QUERIES {
  // UiBootstrap
  UiBootstrap = 'UiBootstrap',
  // dashboard
  dashboardKPIs = 'dashboard-KPIs',
  dashboardBreakdownByAge = 'dashboard-BreakdownByAge',
  dashboardInflowOutflow = 'dashboard-InflowOutflow',
  dashboardExcTeams = 'dashboard-ExcTeams',
  dashboardExcUsers = 'dashboard-ExcUsers',
  dashboardExcVendors = 'dashboard-ExcVendors',
  dashboardExcRootCause = 'dashboard-ExcRootCause',
  itemQuickSearch = 'item-quick-search',
  mfgNameSearch = 'mfg-name-search',
  vendorNameSearch = 'vendor-name-search',
}

type SearchParams = string | string[][] | Record<string, string> | URLSearchParams | undefined;

export interface FetchOptions<PostData> {
  params?: SearchParams;
  data?: PostData;
  headers?: RequestInit['headers'];
  method?: RequestInit['method'];
  ignoreSpoof?: boolean;
}

export function handleSpoofing(): RequestInit['headers'] {
  try {
    const saved = localStorage.getItem(SPOOFED_LS_KEY);
    if (saved !== null) {
      const spoofed: SpoofingClientId = JSON.parse(saved);

      if (spoofed !== null) {
        if (spoofed.startTime && spoofed.startTime + ONE_HOUR > Date.now()) {
          console.log(`[api] spoofed client id: ${spoofed.spoofedClient}`);
          console.log(`[api] spoofed user id: ${spoofed.spoofedUser}`);
          return {
            'X-CLIENT-ID-TO-SPOOF': spoofed.spoofedClient,
            'X-USER-ID-TO-SPOOF': spoofed.spoofedUser,
            'X-CLIENT-ID': spoofed.myClient,
          };
        } else {
          console.log(`[api] spoofed client id expired`);
          localStorage.removeItem(SPOOFED_LS_KEY);
          alert('Spoofing time has expired, please spoof again');
          window.location.href === '/spoof';
        }
      }
    }
  } catch (error) {
    return {};
  }
  return {};
}

type FetchApiFunctionArgs<PostData> = {
  endpoint: string;
  options?: FetchOptions<PostData>;
  jit?: boolean;
  isPublic?: boolean;
};

/**
 * Fetch data from the Clarium API
 * Automatically includes authentication in headers if available
 *
 * @param options Any additional XHR options
 */
export async function fetchApi<JSONResponse, PostData>({
  endpoint,
  options = {},
  jit,
  isPublic,
}: FetchApiFunctionArgs<PostData>): Promise<JSONResponse> {
  try {
    // Check endpoint and build url
    if (!endpoint.startsWith('/')) {
      throw new Error('Invalid API Endpoint');
    }

    // Build URL
    let url = jit ? getJitUrl(endpoint) : getUrl(endpoint);

    // Build options
    const { params, data, ...rest } = options;
    const search = new URLSearchParams(params).toString();
    if (search) {
      url += `?${search}`;
    }

    const opt: RequestInit = {
      method: 'GET',
      headers: {},
      credentials: 'same-origin',
      ...rest,
    };

    // Build JSON body from data object
    if (data) {
      opt.body = JSON.stringify(data);
      options.headers = {
        'Content-Type': 'application/json',
        ...(options.headers || {}),
      };
    }

    // Authorization
    let accessToken;

    if (!isPublic) {
      try {
        const session = await Auth.currentSession();
        accessToken = session.getIdToken().getJwtToken();
        opt.headers = {
          Authorization: `Bearer ${accessToken}`,
          ...(options.ignoreSpoof ? {} : handleSpoofing()),
          ...options.headers,
        };
      } catch (error) {
        // This is an error from Cognito. – 'No current user'
        // It is handled in the onError function of the queryClient
        throw error;
      }
    } else {
      opt.headers = {
        ...options.headers,
      };
    }

    // Send request
    // debug.log('[fetchApi] URL/Options', url, opt);
    const response = await fetch(url, opt);

    // Check HTTP status code
    await checkStatus(response);

    const contentType = response.headers.get('content-type');

    if (options.method === 'DELETE') {
      return {} as unknown as JSONResponse;
    }

    if (contentType && !contentType.includes('application/json')) {
      try {
        const blob = await response.blob();
        return blob as unknown as JSONResponse;
      } catch (error) {
        console.error(error);
      }
    }
    // Parse response
    const json: { data: JSONResponse } = await response.json();
    // Extract data
    return extractData<JSONResponse>(json);
  } catch (error: unknown) {
    throw error;
  }
}

//
// Endpoints
//

export const fetchUiBootstrap = (): Promise<UiBootstrapResponse> => {
  const endpoint = `/ui_bootstrap`;
  return fetchApi({ endpoint });
};

export function bootstrapQuery() {
  return {
    queryKey: [QUERIES.UiBootstrap],
    queryFn: fetchUiBootstrap,
    staleTime: ONE_HOUR,
    retry: false,
  };
}
